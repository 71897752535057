.comment-item {
  padding: 1.5rem 2.625rem 1.5rem 1.5rem;
}

.post-comment-ctr {
  padding: 1.5rem;
}

.tasks-map-height {
  height: calc(100vh - 11.312rem); // Subtracting height of header, nav and fixed footer
  @media screen and (max-width: 60em) {
    height: 100%;
  }
}

.zoom-to-tasks {
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  letter-spacing: '2.69538px';
}

.menu-items-container::-webkit-scrollbar {
  display: none;
}
