.jumbotron-primary {
  height: 600px;

  @media screen and (max-width: 30em) {
    height: 500px;
  }

  h3 {
    @media screen and (min-width: 30em) {
      font-size: 4rem;
    }
  }

  p {
    max-width: 28.5rem;
    line-height: 1.28;
  }
}

.stats-container {
  gap: 1.5rem;

  .stat-number {
    font-size: 4rem;

    @media screen and (min-width: 60em) and (max-width: 90em) {
      font-size: 3.2rem;
    }

    @media screen and (min-width: 45em) and (max-width: 60em) {
      font-size: 2.5rem;
    }

    @media screen and (min-width: 30em) and (max-width: 45em) {
      font-size: 2.125rem;
    }

    @media screen and (max-width: 30em) {
      font-size: 2rem;
    }
  }
}

.mapping-flow-container {
  h3 {
    font-size: 2rem;
    max-width: 800px;

    @media screen and (max-width: 30em) {
      font-size: 1.5rem;
      line-height: 1.28;
    }
  }

  .flow-cards-container-parent {
    position: relative;
    z-index: 1;
  }

  .flow-cards-container {
    position: relative;
    margin-bottom: 6.25rem;
    gap: 2.25rem;
  }

  .flow-cards-container:before {
    content: '';
    width: 100%;
    height: 75%;
    background-image: url('../../assets/img/mask.png');
    position: absolute;
    bottom: -32px; //adjust your positioning as necessary
    right: -32px;
    z-index: -1;

    @media screen and (max-width: 60em) {
      height: 90%;
    }
  }

  .mappingFlowHeadline {
    font-size: 1.125rem;
    line-height: 1.44;

    @media screen and (max-width: 30em) {
      font-size: 1rem;
      line-height: 1.25;
    }
  }
}

.card {
  border-radius: 2px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  p {
    line-height: 1.44;
  }
}

.who-is-mapping {
  position: relative;
  margin-right: calc(8rem + (16.875rem / 2));
  padding-top: 4rem;
  padding-bottom: 6.5rem;

  @media screen and (max-width: 60em) {
    margin-right: 0;
    padding-bottom: 9.5rem;
  }

  h3 {
    font-size: 2.5rem;
    line-height: 1.25;
    padding: 0.4375rem 1.6875rem 0.4375rem 1rem;
    letter-spacing: 1.25px;

    @media screen and (max-width: 30em) {
      font-size: 2rem;
      line-height: 1;
    }
  }

  h4 {
    font-size: 2rem;
    line-height: 1.2;

    @media screen and (max-width: 30em) {
      font-size: 1.5rem;
      line-height: 1.4;
    }
  }

  p {
    line-height: 1.2;
    max-width: 40rem;
    margin-bottom: 6.5rem;

    @media screen and (max-width: 30em) {
      font-size: 1rem;
      line-height: 1.25;
      margin-bottom: 3.125rem;
    }
  }

  .organizations {
    // 3 items per row in flex
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 10%;
    row-gap: 50px;
  }

  .organizations > div {
    // 3 items per row in flex
    flex-grow: 1;
    width: 23%;

    // 2 items per row when screen width is less than 768px
    @media screen and (max-width: 768px) {
      width: 40%;
    }
  }

  .org {
    max-height: 50px;
  }

  .logo-ctr > div {
    flex-grow: 1;
  }

  .contact-card {
    position: absolute;
    right: calc(-16.875rem / 2);
    top: calc(4rem + 4rem);
    width: 16.875rem;
    padding: 1.28rem 1.5rem 1.5rem 1.5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 30em) and (max-width: 60em) {
      width: calc(100% - 4rem);
      height: max-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      top: calc(100% - 60px);
      right: 2rem;
      gap: 5rem;
    }

    @media screen and (max-width: 30em) {
      width: calc(100% - 4rem);
      height: max-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      top: calc(100% - 100px);
      right: 2rem;
      gap: 3rem;
    }
  }
}

.testimonials {
  @media screen and (max-width: 60em) {
    margin-top: 14rem;
  }

  @media screen and (max-width: 30em) {
    padding-bottom: 18rem;
  }

  @media screen and (max-width: 25em) {
    padding-bottom: 25rem;
  }

  h3 {
    font-size: 2.5rem;
    line-height: 1.25;
    letter-spacing: 1.25px;
  }

  h4 {
    line-height: 1.44;
  }

  .testimonial-image {
    height: max(30vw, 430px);
    width: 70vw;
    object-fit: cover;

    @media screen and (min-width: 75em) and (max-width: 90em) {
      height: 550px;
      width: 65vw;
    }

    @media screen and (min-width: 60em) and (max-width: 75em) {
      height: 550px;
      width: 55vw;
    }

    @media screen and (min-width: 30em) and (max-width: 60em) {
      height: 550px;
      width: 60vw;
    }
  }

  .testimonial-image-parent {
    position: relative;
    z-index: 1;
  }

  .testimonial-image-parent:before {
    content: '';
    position: absolute;
    bottom: -32px;
    left: 32px;
    width: calc(100% - 32px);
    height: 75%;
    background-image: url('../../assets/img/mask.png');
    z-index: -1;

    @media screen and (max-width: 60em) {
      bottom: -24px;
      left: 24px;
      width: calc(100% - 24px);
    }
  }

  .citation {
    line-height: 1.28;
    text-align-last: center;
    font-size: 1.3rem;
    z-index: 2;

    @media screen and (max-width: 40em) {
      font-size: 1rem;
    }
  }

  .witness {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  .bio {
    line-height: 1.3;
    font-size: 0.8rem;
  }

  .citation-ctr {
    position: absolute;
    bottom: 10px;
    left: 0px;
    width: max(30%, 445px);

    @media screen and (min-width: 30em) and (max-width: 60em) {
      width: max(50vw, 300px);
    }

    @media screen and (max-width: 30em) {
      width: calc(100% - 4rem);
      top: 320px;
    }
  }

  .testimony {
    display: flex;
    justify-content: flex-end;

    .quotes-icon {
      position: absolute;
      top: -43px;
      left: -15px;
      font-size: 80px;
      line-height: 87px;
    }
  }
}

.jumbotron-sec {
  h3 {
    font-size: 2.5rem;
    line-height: 1.25;
    letter-spacing: 1.25px;
    margin-top: 0;
    margin-bottom: 25px;
  }

  p {
    line-height: 1.44;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 30em) {
      font-size: 1rem;
    }
  }

  .buttons {
    margin-top: 2.625rem;

    @media screen and (max-width: 30em) {
      font-size: 2rem;
    }
  }
}

.jumbotron-sec > div {
  padding-top: 8rem;
  padding-bottom: 8rem;

  @media screen and (max-width: 30em) {
    padding-top: 3.345rem;
    padding-bottom: 3.345rem;
  }
}
